/**
 * PageHome
 */

import React from 'react';
import Confetti from 'react-confetti';
import { getCookie, setCookie } from 'utils/Cookie';
import HeroSearch from 'Components/HeroSearch';
import StreamField from 'Components/StreamField';

import { BaseWrap } from 'Containers/Layouts';
import s from './PageHome.module.scss';

class PageHome extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            showConfetti: false,
        };
    }
    
    initializeConfetti = () => {
        const hasCookie = getCookie('confetti');

        if (!hasCookie) {
            this.setState({
                showConfetti: true,
            }, () => {
                setCookie('confetti', 'seen');
            });
        }
    }

    componentDidMount() {
        if (typeof window !== 'undefined') {

            const { showConfetti } = this.props;
            if (showConfetti) {
                this.initializeConfetti();
            }
        }
    }

    render() {
        const { hero, items, id, } = this.props;
        const { showConfetti } = this.state;

        return (
            <div className={s['PageHome']}>
                {showConfetti && (
                    <Confetti
                        numberOfPieces={1000}
                        recycle={false}
                        colors={['#cb2746', '#501873', '#ffa641', '#2087b1', '#83a81c']}
                    />
                )}
                <HeroSearch {...hero} />

                {items && <StreamField items={items} currentPage={id} />}
            </div>
        );
    }
}

PageHome.defaultProps = {
    entries: [],
    items: [],
};

export default BaseWrap(PageHome);
